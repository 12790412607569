import { useEffect } from "react";

const Homepage = () => {
  useEffect(() => {
    window.location.href = "https://www.thatstheone.com";
  }, []);

  return <></>;
};

export default Homepage;
